import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

  
function RefineryOverview () {
    return (
        <Container>
            <Row className="justify-content-md-left mt-5" >
				<h1 className="m-header">Refinery</h1>
				<div className='m-para'>
					<p>
						<br/>
						Refineries have different configurations depending on its objective and quite simply, whether it has enough money to install more complex units for better returns. The feedstock refers to the raw crude oil that has been bought or traded. It is quite common for the feedstock to consist of many different types of crude oil grades. This also contributes to the the proportion of each output product produced. Another name for the blend of feed stock is crude slate
						<br/>
						<br/> 
						A barrel roughly contains 42 gallons and can produce additional gallons of products depending on the configuration and the refinery slate. The term used in this case is refinery or processing gains.
						<br/>
						<br/> 
						Click the Explore button above to find out more!
						<br/>  
						<br/>
					</p>
				</div>
            </Row>
        </Container>
)}
// ReactDOM.render(<MyComponent />, document.body);
export default RefineryOverview;
