import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

  
function TankerType () {
    return (
        <Container>
            <Row className="justify-content-md-left mt-5" >
				<h1 className="m-header">Tanker Types</h1>
				<div className='m-para'>
				<p>
					<br/>
					Tankers can be further seperated into the products they are carrying and by their DWT.
					<br/>
					<br/>
					<font><b>Oil/Chemical Tankers</b></font>
					<br/>
					<br/>
					Tankers are used to transport clean and dirty products. Clean product tankers, also known as white oil tankers, will usually have a coating of zinc or somethinng epoxy based to prevent the walls of the tank from corrosion. Depending on what she is carrying, the tanker must fall into a certain IMO ship type category. For example, in order to carry the most dangerous and polluting products, the tanker must be of Type 1,where by the cargo tanks have a suitable distance between one another, each tank cannot exceed 1240 m3 and the tank must be made out of stainless steel. There are 3 IMO ship type categories, with Type 3 being the most lenient. Cargos such as vegetable oil do not require such specifications. 
					<br/>
					<br/>
					Dirty Tankers or black oil tankers carry raw crude oil or the heavier products such as bitumen or asphalt. Some tankers have heating mechanisms to heat the oil through steam to make it easier for discharge. After the tannkers discharge their load, vessels cann use Crude Oil Washers (COW) to  remove any residues stuck onto the tank. The residues collected from these washers are called slops and are stored in slop tanks until it can be disposed of safely at ports. Besides Slop Tanks, tankers also have ballast tanks to store the ballast water.
				</p>
					<br/>
					<br/>
					<table className="tg">
					<thead>
					  <tr>
					    <th className="tg-0pky">Clean Tanker Name</th>
					    <th className="tg-0pky">DWT</th>
					    <th className="tg-0pky">Dirty Tanker</th>
					    <th className="tg-0pky">DWT</th>
					  </tr>
					</thead>
					<tbody>
					  <tr>
					    <td className="tg-0pky">General Purpose (GP)</td>
					    <td className="tg-0pky">10,000 - 24,999</td>
					    <td className="tg-0pky">Product Tanker</td>
					    <td className="tg-0pky">10,000 - 60,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Medium Range (MR)</td>
					    <td className="tg-0pky">25,000 - 44,999</td>
					    <td className="tg-0pky">Panamax</td>
					    <td className="tg-0pky">60,000 - 80,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Long Range 1 (LR1)</td>
					    <td className="tg-0pky">45,000 - 79,999</td>
					    <td className="tg-0pky">Aframax</td>
					    <td className="tg-0pky">80,000 - 120,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Long Range 1 (LR2)</td>
					    <td className="tg-0pky">80,000 - 159,999</td>
					    <td className="tg-0pky">Suezmax</td>
					    <td className="tg-0pky">120,000 - 200,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Very Large Crude Carrier (VLCC)</td>
					    <td className="tg-0pky">160,000 - 319,999</td>
					    <td className="tg-0pky">Very Large Crude Carrier (VLCC)</td>
					    <td className="tg-0pky">160,000 - 320,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Ultra Large Crude Carrier (ULCC)</td>
					    <td className="tg-0pky">&gt; 320,000</td>
					    <td className="tg-0pky">Ultra Large Crude Carrier (ULCC)</td>
					    <td className="tg-0pky">&gt; 320,000</td>
					  </tr>
					  </tbody>
					</table>
					<br/>
					<br/>	
				<p>
					<font><b>LNG/LPG Tankers</b></font>
					<br/>
					<br/>
					Liqufied Natural Gas (LNG) and Liquefied Petroleum Gas (LPG) can be transported in tankers under special conditions to maintain its liquefied state.
					1. Fully Pressurized
					<br/>
					2. Semi Pressureized and refrigerated
					<br/>
					3, Fully Refrigerated
					<br/>
					<br/>
					The cargo tank of these tankers can have a few designs like prismatic, membrane or spherical. Prismatic tanks allow the ships carrying capacity to be maximixed as the shape of the tank is closer to that of the vessel. They are also often fully refrigerated. All of these features incereases the ecnonomic advantages as a larger volume of cargo can be transported. Membrane tanks are similar to prismatic ones, except that is is not-self-supporting. They are in direct contact with the hull structure and have a waffle like pattern to handle the thermal contraction on cool down. Prismatic tanks are usually supported by blocks made out of plywood, mounted onto steel supports. Spherical tanks are probably the most common and more distinguishable than the other types as part of the dome can be seen protruding out of the ship. The tanks sit on a layer of insulation and support beams. However due to imperfect insulation, a certain amount gas will still be present. This is called the boil-off gas (BOG) which can nbe reliquefied or used as fuel. A lot of ship space is wasted due to the insulation required around it and the support beams. Its spherical tank shape allows the pressure to be evenly distributed.
				</p>
					<br/>
					<br/>
					<table className="tg">
					  <thead>
					  <tr>
					    <th className="tg-0pky">LNG Tanker Name</th>
					    <th className="tg-0pky">M<sup>3</sup></th>
					    <th className="tg-0pky">LPG Tanker Name</th>
					    <th className="tg-0pky">M<sup>3</sup></th>
					  </tr>
					  </thead>
					  <tbody>
					  <tr>
					    <td className="tg-0pky">Conventional</td>
					    <td className="tg-0pky">145,000 - 154,000</td>
					    <td className="tg-0pky">Small Gas Carrier (SGC) - Pressurized</td>
					    <td className="tg-0pky">&lt; 4,999</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Q-Flex</td>
					    <td className="tg-0pky">210,000 - 216,000</td>
					    <td className="tg-0pky">Small Gas Carrier (SGC) - Pressurized | Semi-Refrigerated</td>
					    <td className="tg-0pky">5,000 - 14,999</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Q-Max</td>
					    <td className="tg-0pky">263,000 - 266,000</td>
					    <td className="tg-0pky">Handy Gas Carrier (HGC) - Semi-Refrigerated | Fully-Refrigerated</td>
					    <td className="tg-0pky">15,000 - 24,999</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky"></td>
					    <td className="tg-0pky"></td>
					    <td className="tg-0pky">Medium Gas Carrier (MGC) - Fully-Refrigerated</td>
					    <td className="tg-0pky">25,000 - 39,999</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky"></td>
					    <td className="tg-0pky"></td>
					    <td className="tg-0pky">Large Gas Carrier (LGC) - Fully-Refrigerated</td>
					    <td className="tg-0pky">40,000 - 59,999</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky"></td>
					    <td className="tg-0pky"></td>
					    <td className="tg-0pky">Very Large Gas Carrier (VLGC) - Fully-Refrigerated</td>
					    <td className="tg-0pky">&gt; 60,999</td>
					  </tr>
					</tbody>
					</table>
					<br/>
					<br/>
				<p>
					<font><b>Floating Production Storage Offloading (FPSO)</b></font>
					<br/>
					<br/>
					FPSO is a floating vessel designed to receive raw crude oil from an oilfield and for storage until the oil can be offloaded to a vessel. The crude oil can also be transported back to the main land via pipes. Oil tankers can be converted to an FPSO. They can be identified by the prescence of a flare tower or special mooring systems.
					<br/>
					<br/>
					<font><b>Floating Stirage Regasification Unit (FSRU)</b></font>
					<br/>
					<br/>
					FSRU can be seen as an offshore installations, similar to FPSO whereby they receive LNG from a field and offload it to vessels. They are equipped with regasification units and unloading arms or hoses so that the heating and liquefaction process can carried out onboard
					<br/>
					<br/>
					<font><b>Bulk Carriers</b></font>
					<br/>
					<br/>
					Bulk Carriers are primarily used in carrying dry cargo items such as coal, ores, sand etc. They are identifiable by the large open decks and hatches.
					<br/>
					<br/>
					<font><b>General Cargo</b></font>
					<br/>
					<br/>
					General cargo ships can carry various types of cargoes such as containers or dry bulk like bulk carriers. The bulk of the international trade are carried by general cargo ships. Some are equipped with cranes and other mechanisms to load or unload cargo at ports that are less developed.
				</p>
					<br/>
					<br/>
					<table className="tg">
					  <thead>
						<tr>
							<th className="tg-0pky">Dry Bulk Tanker Name</th>
							<th className="tg-0pky">DWT</th>
						</tr>
					  </thead>
					  <tbody>
					  <tr>
					    <td className="tg-0pky">Handysize</td>
					    <td className="tg-0pky">20,000 - 40,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Handymax</td>
					    <td className="tg-0pky">40,000 - 50,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Supramax</td>
					    <td className="tg-0pky">50,000 - 60,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Ultramax</td>
					    <td className="tg-0pky">60,000 - 75,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Panamax</td>
					    <td className="tg-0pky">75,000 - 80,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Kamsarmax</td>
					    <td className="tg-0pky">80,000 - 98,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Post-Panamax (Baby-Capers)</td>
					    <td className="tg-0pky">98,000 - 125,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Capesize</td>
					    <td className="tg-0pky">125,000 - 220,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Very Large Ore Carrier (VLOC)</td>
					    <td className="tg-0pky">220,000 - 320,000</td>
					  </tr>
					  <tr>
					    <td className="tg-0pky">Ultra Large Ore Carrier (ULOC/Valemax)</td>
					    <td className="tg-0pky">&gt;320,000</td>
					  </tr>
					</tbody>
					</table>
					<br/>
					<br/>
				<p>
					<font><b>Reefer Ship</b></font>
					<br/>
					<br/>
					Reefer ships are used to transport perishables which require a certain level of temperature such as fish, fruits or vegetables. They have large insulated hatches on the deck.
					<br/>
					<br/>
					<font><b>Ro-Ro Ship</b></font>
					<br/>
					<br/>
					Roll-on/Roll-off of otherwise known as Ro-Ro ships are designed to carry wheeled cargoes like cars, trucks, trailers etc. They are driven on and off the ship using its self-propelled modular transporter. These ships are identifiable by the ramps or open decks.
					<br/>
					<br/>
					<font><b>Semisubmersible Heavy Lift Vessel</b></font>
					<br/>
					<br/>
					Heavy cargo such as platform units, oil platforms, cranes or vessels etc. are transportedd on this kind of vessel as they utilize the Float-on/Float-off (Flo-Flo) method
					<br/>
					<br/>
				</p>
				</div>
            </Row>
        </Container>
)}
// ReactDOM.render(<MyComponent />, document.body);
export default TankerType;
