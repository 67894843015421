import React, { Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import './App.css';
import { createBrowserRouter, BrowserRouter, RouterProvider, NavLink, Routes, Route, Router, Link } from 'react-router-dom';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Home from './components/home/home';
import AIS from './components/maritime/ais';
import OpsOverview from './components/maritime/ops_overview';
import OpsUpstream from './components/maritime/ops_upstream';
import OpsMidstream from './components/maritime/ops_midstream';
import OpsDownstream from './components/maritime/ops_downstream';
import RefineryOverview from './components/maritime/refinery_overview';
import RefineryBreakdown from './components/maritime/refinery_breakdown';
import RefineryEconomics from './components/maritime/refinery_economics';
import TankerOverview from './components/maritime/tanker_overview';
import TankerMarketParties from './components/maritime/tanker_market_parties';
import TankerType from './components/maritime/tanker_type';
import PythonDjango from './components/tech/python_django';
import GetSGCarpark from './components/tech/get_carpark';


export default function App() {
    document.title = "Miracle"
    document.getElementsByTagName('meta')["description"].content = "Portfolio";
    return (
        <div className="App">
            <BrowserRouter>
                <Navigation/>
                {/* {< Home />} */}
                <Routes>
                    <Route exact path='/' element={< Home />}></Route>
                    {/* <Route path='/maritime' element={< NavigationMaritime />}></Route> */}
                    {/* <Route exact path='/maritime/ais' element={< AIS />}></Route> */}
                    <Route path='/maritime/*' element={< NavigationMaritime />}></Route>
                    <Route path='/tech/*' element={< NavigationTech />}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

const Navigation = () => (
    <Container className="d-flex justify-content-end p-3">
      <Dropdown as={ButtonGroup} className="btn-color">
        <Dropdown.Toggle id="btn-color">Menu</Dropdown.Toggle>
        <Dropdown.Menu className="m-header">
            {/* <Dropdown.Divider /> */}
            <Dropdown.Item><Link to="/">Miracle</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/maritime">Maritime</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/tech">Tech</Link></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Container>
);


function NavigationMaritime() {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    return (
      <div>
        <Container className="align-items-center justify-content-center">
            <Row className="justify-content-md-left">
                <Col xs lg="3" className="box-outline m-5 p-5" style={{"height" : "20%"}}>
                    <h2 className="m-header">Vessels</h2>
                    <div className="m-para">
                        <p>
                            little maritime nuggets
                        </p>
                        <ul>
                            <li ><Link className="cool-link link-format" to='/maritime/ais'>AIS</Link></li>
                            <li ><Link className="cool-link link-format" to='/maritime/operations'>Operations</Link></li>
                            <ul>
                                <li ><Link className="cool-link link-format" to='/maritime/operations-upstream'>Upstream</Link></li>
                                <li ><Link className="cool-link link-format" to='/maritime/operations-midstream'>Midstream</Link></li>
                                <li ><Link className="cool-link link-format" to='/maritime/operations-downstream'>Downstream</Link></li>
                            </ul>
                            <li ><Link className="cool-link link-format" to='/maritime/refinery'>Refinery</Link></li>
                            <ul>
                                <li ><Link className="cool-link link-format" to='/maritime/refinery-breakdown'>Breakdown</Link></li>
                                <li ><Link className="cool-link link-format" to='/maritime/refinery-economics'>Economics</Link></li>
                            </ul>
                            <li ><Link className="cool-link link-format" to='/maritime/tankers'>Tankers</Link></li>
                            <ul>
                                <li ><Link className="cool-link link-format" to='/maritime/tankers-type'>Types</Link></li>
                                <li ><Link className="cool-link link-format" to='/maritime/tankers-marketparties'>Market &amp; Parties</Link></li>
                            </ul>
                        </ul>
                    </div>
                </Col>
                <Col className="m-5">
                <Card>
                    <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted m-header">Navigate via the side menu, to go back, use the top menu</Card.Subtitle>
                        <Card.Text className="m-para">
                        </Card.Text>
                    </Card.Body>
                </Card>
                    <Routes>
                        <Route exact path='/ais' element={< AIS />}></Route>
                        <Route exact path='/operations' element={< OpsOverview />}></Route>
                        <Route exact path='/operations-upstream' element={< OpsUpstream />}></Route>
                        <Route exact path='/operations-midstream' element={< OpsMidstream />}></Route>
                        <Route exact path='/operations-downstream' element={< OpsDownstream />}></Route>
                        <Route exact path='/refinery' element={< RefineryOverview />}></Route>
                        <Route exact path='/refinery-economics' element={< RefineryEconomics />}></Route>
                        <Route exact path='/refinery-breakdown' element={< RefineryBreakdown />}></Route>
                        <Route exact path='/tankers' element={< TankerOverview />}></Route>
                        <Route exact path='/tankers-type' element={< TankerType />}></Route>
                        <Route exact path='/tankers-marketparties' element={< TankerMarketParties />}></Route>
                    </Routes>
                </Col>
            </Row>
        </Container>

      </div>
    );
}

function NavigationTech() {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    return (
      <div>
        <Container className="align-items-center justify-content-center">
            <Row className="justify-content-md-left">
                <Col xs lg="3" className="box-outline m-5 p-5" style={{"height" : "20%"}}>
                    <h2 className="m-header">Tech</h2>
                    <div className="m-para">
                        <p>
                            tutorials and randomness
                        </p>
                        <li ><Link className="cool-link link-format" to='/tech/python-django'>Python Django</Link></li>
                        <li ><Link className="cool-link link-format" to='/tech/get-carpark'>Carpark rates in SG</Link></li>
                    </div>
                </Col>
                <Col className="m-5">
                <Card>
                    <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted m-header">Navigate via the side menu, to go back, use the top menu</Card.Subtitle>
                        <Card.Text className="m-para">
                        </Card.Text>
                    </Card.Body>
                </Card>
                    <Routes>
                        <Route exact path='/python-django' element={< PythonDjango />}></Route>
                        <Route exact path='/get-carpark' element={< GetSGCarpark />}></Route>
                    </Routes>
                </Col>
            </Row>
        </Container>

      </div>
    );
}
