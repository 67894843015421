import React, { useState, useEffect, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

import Pin from './img/pin.png';

import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { Popup } from 'react-leaflet/Popup'
import { Marker } from 'react-leaflet/Marker'
import L from 'leaflet';

const API = "http://localhost:5000/search?location"

function CarparkRequest(arg, data) {
	return fetch(`""=${arg}`,
	{
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Access-Control-Allow-Origin': 'localhost'
		},
		body: JSON.stringify(data)
	  }
	)

}

function GetSGCarpark () {
	const [data, setData] = useState([]);
	const handleSubmit = (event) => {
		event.preventDefault();
		var searchValue = event.target.elements.carparktext.value
		CarparkRequest(searchValue).then(
			async (resp) => {
			  const json= await resp.json()
			  setData(json)
		})		
	}

	const pinArray = [...new Map(data.map(item => [item["point_of_interest"], item])).values()];
	pinArray.forEach(element => {
		let textArray = data.filter(x => x["ref_id"] == element["ref_id"])
		element["textArray"] = textArray
		element["coords"] = [element["latitude"], element["longitude"]]
	});

	const center = [103.802188, 1.360412]
	var pinPic = L.icon({
		iconUrl: Pin,
		shadowUrl: null,
		iconSize:     [20, 50], // size of the icon
		shadowSize:   null, // size of the shadow
		iconAnchor:   null, // point of the icon which will correspond to marker's location
		shadowAnchor: null,  // the same for the shadow
		popupAnchor:  [0, -10] // point from which the popup should open relative to the iconAnchor
	});


    return (
        <Container className="" fluid>
            <Row className="justify-content-md-left mt-5" >
			<h1 className="m-header">Carpark Rates</h1>
			<div className='m-para'>
				<p>Small project to search for a location and retrieve all carparks with their rates within the vicinity</p>
				<p>*Decided to disable this*</p>
				<Form onSubmit={handleSubmit}>
					<InputGroup className="mb-3">
						<InputGroup.Text>Search</InputGroup.Text>
						<Form.Control id="carparktext" aria-describedby="basic-addon3" />
						<Button className="btn-color m-header" id="carpark-submit" type="submit" disabled="true">Submit</Button>
					</InputGroup>
				</Form>
				<br/>
				<MapContainer center={[1.360412, 103.802188]} zoom={11} scrollWheelZoom={false}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{pinArray.map((location) => (
					<Marker
					position={location.coords}
					icon={pinPic}
					>
					<Popup>
						<b>{location.point_of_interest}</b>
						{location.textArray.map((elem) => (
							<div>{elem.duration} 
							<br/>
							{elem.rates}
							<br/><br/></div>
							
						))}
	

					</Popup>
					</Marker>
				))}
				</MapContainer>

			</div>
            </Row>
        </Container>
)}

export default GetSGCarpark;
