import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import TankerLen from './img/tanker_len.svg';
import Draught from './img/draught.svg';
  
function TankerOverview () {
    return (
        <Container>
            <Row className="justify-content-md-left mt-5" >
				<h1 className="m-header">Tankers</h1>
				<div className='m-para'>
				<p>
					Flag states provide a nationality for the ships that allow for the identification for legal and commercial purpose to trade. They also have legal authority over governing activities of merchant ships such as commercial and operational regulation.  With registration, there will be an extension of the national territories while at sea and they provide protection for vessels registered under them. Choice of registration is important due to interdependence between legal regulation and ship operating economics. For exmaple, tax, company law and financial law, compliance with maritime safety convention, crewing and terms of employment and naval protection. Most importantly, flag states help to ensure compliance of ship to the international regulations set by the IMO that the flag state has adopted and enacted into their legislation. 
					<br/>
					<br/>
					The International Maritime Organisation (IMO), is a agency by the United Nations to ensure the safety and security of the vessels and prevent maritime pollution and disasters. They set standards and code of conduct to be adopted by countries but are not responsible for enforcing them. The enforcement of rules falls onto the member states for foreign vessels visiting their ports and the vessels registered under their flag. Having the IMO to design the rules and regulations helps to standardize it for all countries and vessels to follow. 
					<br/>
					<br/>
					Before we carry on, here are some important vessel attributes
					<br/>
					<br/>
					<font><b>1. Length</b></font>
					<br/>
					<br/>
					<img src={TankerLen} className="display-flex m-5" alt="TankerLen" width="100%" height="100%" />
					<br/>
					<br/>
					 <b>a. Length Overall (LOA)</b>
					<br/>
					<br/>
					LOA refers to the maximum length of the vessel, measured parallel to the waterline. When docking the ship at a port, one has to ensure that the port can accomodate the ships LOA (unless one wants the ship to be damaged).
					<br/>
					<br/>			
					 <b>b. Length Between Perpendiculars (LPP)</b>
					<br/>
					<br/>
					LPP refers to the length of a ship in contact with the water from the main bow perpendicular member to the after surface of the sternpost. It is to approximate the ship's carrying capacity.
					<br/>
					<br/>			
					 <b>b. Length of waterline (LWL)</b>
					<br/>
					<br/>
					LWL refers to the length of the vessel at which it is in contact with the water. It is used to calculate several properties or attributes of the ship, such as the the displacement of water caused by the vessel of the theoratical hull speed. Having a longer LWL would indicate that the vessel can move at a higher speed as there is a greater sail area.
					<br/>
					<br/>
					<font><b>2. Tonnage</b></font>
					<br/>
					<br/>
					 <b>a. Gross Tonnage (GT)</b>
					<br/>
					<br/>
					GT is a measure of the ship's total enclosed space of volume, whereby 100 cubic feet is equal to 1 ton.
					<br/>
					<br/>
					 <b>b. Net Tonnage (NT)</b>
					<br/>
					<br/>
					Similar to GT, NT measures the vessel's useful earning capacity. The space where it can store goods.
					<br/>
					<br/>
					<font><b>3. Dead Weight Ton (DWT)</b></font>
					<br/>
					<br/>
					DWT is the maximum amount of weight the vessel can have with repect to the Summer Load Line draft. It is a hazard when the vessel carries more than her DWT.
					<br/>
					<br/>
					<font><b>4. Draught</b></font>
					<br/>
					<br/>
					Draught is the vertical distance between the waterline and the bottom of the ship's hull. It indicates the weight of the cargo by calculating the displacement of the water. Below is an image indicating the vessel's acceptable draught in different weather conditions. The water during winter conditions is denser, hence allowing the ship to have a higher draught.
					<br/>
					<img src={Draught} className="display-flex m-5" alt="Draught" width="100%" height="100%" />
					<br/>
					<br/>
				</p>
				</div>
            </Row>
        </Container>
)}
// ReactDOM.render(<MyComponent />, document.body);
export default TankerOverview;
