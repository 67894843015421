import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import refineryPic from './img/refinery.svg';
import FCC from './img/fcc.svg';

  
function RefineryBreakdown () {
    return (
        <Container className="" fluid>
            <Row className="justify-content-md-left mt-5" >
			<h1 className="m-header">Refinery Breakdown</h1>
			<div className='m-para'>
			<p>
					<br/>
					<br/>
					<img src={refineryPic} className="display-flex m-5" alt="refineryPic" width="100%" height="100%" />
					<br/>
					<br/>
					Crude oil feed contains a mixture of molecules with different boiling point temperatures.
					<br/>
					<br/>
					<font><b>1. Desalter</b></font>
					<br/>
					<br/>
					The raw crude oil can contain several contaminants such as salt which can corrode pipes. Hence, they are removed before it enters the distillation units. At this point, the temperature of the oil is around 250 degrees celcius. It is then further heated up to 350 degrees celcius via a furnace before it enters the distillation column. Sometimes, the furnace uses the residual fuel or gas from the refinery as its power source. This is due to residual fuel being worth very little on the market and the transportation of gas can be very expensive.
					<br/>
					<br/> 
					Temperatures cannot exceed 370 degrees celcius as the molecules could potentially undergo thermal cracking and form petroleum coke. This would result in pipes being clogged.
					<br/>
					<br/> 
					<font><b>2. Atmospheric Distillation</b></font>
					<br/>  
					<br/>
					Atmospheric Distillation Column(ADU), can have several names such as Crude Distillation Unit(CDU) or Primary Distillation Unit. Its conditions are at atmospheric pressure and a temperature of around 400 degrees celcius. Inside the unit, there are several perforated trays and openings at the ends of each tray. In addition, the temperature profile varies from the top to the bottom of the unit. Products with lower boiling points will be vaporized, leaving behind the less volatile liquids. As the vapor rises, it will condense at the right conditions, allowing it to be drawn off. The heavier products will fall through the openings at the ends of the tray. Since the crude oil feed contains a mixture of molecules, it would be worth noting that each product drawn off at a certain stage will have a range of temepratures as well. These stages are also known as dIstillation cuts
					<br/>
					<br/>
					Products coming out directly from the ADU are called straight run products and can be seperated into 3 categories - light ends, middle distillates and heavy ends
					<br/>
					<br/>
					 Light Ends - Gasoline and naphtha
					<br/>
					 Middle Distillates - Jet Fuel and Kerosene
					<br/>
					 Heavy Ends - Gasoil and Diesel
					<br/>
					<br/> 
					<font><b>3. Vacuum Distillation</b></font>
					<br/>  
					<br/>
					The Vacuum Distillation Unit (VDU) acts similarly to the ADU, except that the pressure is very much below atmospheric pressure (~10 mmHg). This is to cater to the heavy atmospheric bottoms which boils above 370 degrees celcius. Having a lower operating pressure would allow these molcules to vaporize more easily. Some products from the VDU include vacuum gasoil, asphalt and bitumen.
					<br/>
					<br/> 
					<font><b>4. Cracking</b></font>
					<br/>  
					<br/>
					Cracking involves the breaking down of larger molecules into smaller ones and a distillation column follows after that for recovery. A regenerator is also present to capture the catalyst particles to be reused. Having such units after the ADU and VDU allows one to have greater control over the outputs to suit the seasonality
					<br/>
					<br/>
					 <b>a. Catalytic Cracking (FCC)</b>
					<br/>
					<br/>
					<img src={FCC} className="display-flex m-5" alt="refineryPic" width="100%" height="100%" />
					<br/>
					The bottoms from the fractionator or otherwise known as cycle oil can be sold or recycled back into the FCC to produce other products.
					<br/>
					<br/>
					 <b>b. Hydrocracking</b>
					<br/>
					<br/>
					A hydrocracker operates similarly to the FCC, except for the fact that hydrogen is fed into the reactor as well. Heavy hydrocarbons are cracked into unsaturated hydrocarbons and will react with the hydrogen to form saturated hydrocarbons. The focus of hydrocrackers is more on the production of middle distillates like jet, kerosene and diesel
					<br/>
					<br/>
					 <b>c. Thermal Cracking</b>
					<br/>
					<br/>
					Steam cracking involves a very high temperature steam stream to break products like propane, naphtha or light gasoil. It is mainly used to produce petrochemicals. This also allows the hydrogen from steam to bond with the molecules to form new products.
					<br/>
					<br/>
					One example of thermal cracking are cokers. Cokers break the residual ends into lighter products and a side product off this process is coke whicch is a coal like substance. It is mainly used when the crude slate is heavy so that more valuable lighter products can be yielded.
					<br/>
					<br/>
					<font><b>5. Combining</b></font>
					<br/>  
					<br/>
					Combining involves bringing smaller molcules together to form larger and more valuable products
					<br/>
					<br/>
					 <b>a. Alkylation</b>
					<br/>
					<br/>
					Unsaturated gaseseous byproducts are combined with an isoparaffin like isobutane, together with a catalyst to produce high-octane blendstock for gasoline 
					<br/>
					<br/>
					 <b>b. Polymerization</b>
					<br/>
					<br/>
					Unlike Alkylation where unsaturated byproducts are combined with others, poylerization involves linking several molecules together.
					<br/>
					<br/>
					<font><b>5. Enhancements</b></font>
					<br/>
					<br/>
					Merox treaters and hydrotreaters are examples of enhancements or treatment so that the product has better specifications. For example, hydrotreaters can improve the Smoke Point Improvement (SPI) of fuels to ensure it gets burnt more thoroughly. Merox treaters removes mercaptans which is a foul smelling sulfur compounds. Mercaptans are actually added to odourless natural gas so that gas leaks are detected faster.
					<br/>
					<br/>
				</p>
			</div>

            </Row>
        </Container>
)}
// ReactDOM.render(<MyComponent />, document.body);
export default RefineryBreakdown;
