import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

  
function OpsUpstream () {
    return (
        <Container className="" fluid>
            <Row className="justify-content-md-left mt-5" >
			<h1 className="m-header">Operations Upstream</h1>
			<div className='m-para'>
				<p>
					<br/>
					Exploration and Production (E&P) is the gateway to ensuring an oil field is sustainable and profitable in the long run.
					In order for companies to begin E&P, permission must be sorted for the surface and the minerals under it. Although a private individual owns the surface, the minerals belong to the governement most of the time. Here is rough guide of an E&P company.
					<br/>  
					<br/>
					<font><b>1. Obtain an agreement! Some agreements one might have ...</b></font>
					<br/>  
					<br/>
					<b>a. Concession Agreements</b>
					<br/>
					<br/>
					These agreements used to be very common in the 1900s between private companies or individuals with the OPEC govenernments. They allow one to start looking and drilling for oil although it is not guaranteed they will be responsible for the extraction after that. All risks and costs are bourne by holders of the agreements and thus they must try to recoup their losses when they start producing. This is otherwise known as equity production where a portion of the revenue goes to the leasor as well.
					<br/>
					<br/>
					 <b>b. Production Sharing Agreements (PSA)</b>
					<br/>
					<br/>
					PSAs work similarly to the concession agreements except that ownership of the entire enterprise is handed back to the owner of the mineral rights. Countries that limit the percentage of direct equity ownership by foreigners often use this agreement.
					<br/>
					<br/>
					 <b>c. Joint Venture Agreements (JV)</b>
					<br/>
					<br/>
					When the risk of a project is too high, companies can opt for a JV with National Oil Companies or other companies. Some high risk projects include geological factors (i.e deep water) or politics (i.e turmoil in the country to dirupt operations).
					<br/>
					<br/>
					Some other agreements include service contracts and production contracts. Service contracts are the least popular because the producer will only get paid if oil production can be carried out at the end of the exploration. They do it on behalf the owner and this is a significant gamble because the field might not even have enough oil to recoup losses. Production contracts occur when the owner pays a company to improve oil recovery. If oil can be recovered at a better rate, the company will get paid.
					<br/>
					<br/>
					<font><b>2. Start testing and drilling!</b></font>
					<br/>  
					<br/>
					Many E&P companies often contract out the drilling part to external companies just specialising in this field. These companies will then provide all the equipment needed like rigs as well as the crew maintaining the rig site. Geologists and phycisists will also be brought in to work in conjuction with the drilling company.
					<br/>  
					<br/>
					Exploratory and appraisal wells are drilled in order to get a sense of whether there is oil underneath and if so, how big is the reservoir. This is often kept confidential to prevent other companies from potentially poaching. Some alternative methods of testing or detecting include ...
					<br/>  
					<br/>
					- Geological surveys where rocks are analyzed
					<br/>
					- Remote imagery  obtained from satellites where geologist keep a look out for fault lines or cap rock formation
					<br/>
					- Seismic surveys which involves analysing how sound waves are bouncing off the rocks under the surface. This can often lead one to have an underground 'image'
					<br/>  
					<br/>
					<font><b>3. Finish the well</b></font>
					<br/>  
					<br/>
					When the appropriate depth is reached, a decision has to be made on whether to hole is profitable or not. If it does not meet expections, the well is sealed, or otherwise known as a dry hole. Then, the whole cycle of exploring and appraisal starts again.
					<br/>  
					<br/>
					Lets imagine that one strikes gold and finds that it will be profitable to continue maintaining the site, production equipment will be brought in, to build the proper facilities required to extract and store. Pipelines may be built directly to the site, saving on transportation cost. On top of that, several safety equipment will be installed to prevent severe accidents such as a blow out.
					<br/>  
					<br/>
					The initial extraction of the oil is called primary recovery where the main concept is 'pulling' oil out. Under primary recovery, there is flush production and settled production. Flush production indicates that the reservoir pressure is high enough to displace the oil out onto the surface. Once the pressure drops, we move onto settled production where equipment is brought in to increase the pressure. For example, sucker rod pumps where a ball is dropped and the oil is brought closer to the surface when the pump rises.
					<br/>  
					<br/>
					Secondary methods of recovery can otherwise be explained as 'pushing' oil out. For example, water can be injected into the well to displace the oil. When these methods have been exhausted, the last type of recovery once can try is Enhanced Oil Recovery (EOR). This involves the fracturing of rock formations to enhance permeability and reducing the viscosity of oil. The most popular method being Hydraulic Fracturing where water is pumped at high pressure to create fissures in the rock. Sometimes sand is also injected to hold the fissures in place so that it does not mix with the oil. All of these will cause the oil to move towards the main well.
					<br/>  
					<br/>
				</p>
			</div>

            </Row>
        </Container>
)}
// ReactDOM.render(<MyComponent />, document.body);
export default OpsUpstream;
