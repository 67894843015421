import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

  
function OpsOverview () {
    return (
        <Container className="" fluid>
            <Row className="justify-content-md-left mt-5" >
			<h1 className="m-header">Operations Breakdown</h1>
			<div className="m-para">
				<p>
					<br/>
					Begins from prospecting various places to see if there is any value in bringing a full scale operation
					to that particular site. If the evaluation goes well, they will proceed to extract the oil. This is also known as 
					Exploration and Production (E&P) or the upstream side of things.						
					<br/>
					<br/>
					After the oil has been extracted, we move on to the mid stream portion where it involves the transportation of the raw liquids
					from one point to another. For example a pipeline linking the extraction site to the refinery or trucking.
					<br/>
					<br/>
					Finally, we move on to the downstream operations. Once the raw liquids reach the refinery, it gets refined into more valuable products.
					This includes gasoline, jet fuel, plastic pellets etc. Some of this refining occurs in the midstream part but mainly downstream. Other activities
					include trading and product marketing.
				</p>
			</div>
            </Row>
        </Container>
)}
// ReactDOM.render(<MyComponent />, document.body);
export default OpsOverview;
