import React, { useState, useCallback, useEffect, useContext, createContext, useRef } from 'react';
import logo from './img/wolf.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
  } from "react-router-dom";
  
function Home (){
    return (
        <Container className="display-flex align-items-center justify-content-center" >
            <Row className="align-items-center justify-content-center my-auto">
                <Col>
                    <img src={logo} className="display-flex m-5" alt="logo" width="300" height="300"/>
                </Col>
                <Col className="box-outline box-outline-purple p-5" size="xxl" width="300" height="300">
                    <Row className="justify-content-center m-header">
                        <h1 className="text-center"><u>{RenderWelcome ()}</u></h1>
                        Miracle Portfolio
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs lg="4" className="justify-content-md-left box-outline m-5 p-5">
                    <h2 className="m-header">👋 I am &#8896;</h2>
                    <p className="m-para">
                        A data engineer who enjoys exploring random ideas and reading various things. Information from this site was gathered from multiple books and sources. 
                    </p>
                    <p className="m-para">
                        I maintain this site to fulfill my curiosity
                    </p>
                </Col>
                <Col xs className="m-5 flex">
                    <Row>
                        <Card>
                        <Card.Body>
                            <Card.Title className="m-header">Vessels</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted m-header">Little nuggets of knowledge</Card.Subtitle>
                            <Card.Text className="m-para">
                                Crash course from AIS Signals to Refinery Economics
                            </Card.Text>
                            <div>
                                <a className="m-para cool-link link-format" href='/maritime/ais'>AIS</a>&nbsp;&nbsp;
                                <a className="m-para cool-link link-format" href='/maritime/Operations'>Operations</a>&nbsp;&nbsp;
                                <a className="m-para cool-link link-format" href='/maritime/Refinery'>Refinery</a>&nbsp;&nbsp;
                                <a className="m-para cool-link link-format" href='/maritime/Tankers'>Tankers</a>&nbsp;&nbsp;
                            </div>
                        </Card.Body>
                        </Card>
                    </Row>
                    <Row className="mt-3">
                        <Card>
                        <Card.Body>
                            <Card.Title className="m-header">Tech</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted m-header">Migration in progress from v1...</Card.Subtitle>
                            <Card.Text className="m-para">
                                From tutorials to whatever is in my mind
                            </Card.Text>
                            <div>
                                <a className="m-para cool-link link-format" href='/tech/python-django'>Django</a>&nbsp;&nbsp;
                                <a className="m-para cool-link link-format" href='/tech/get-carpark'>Carpark Flask</a>&nbsp;&nbsp;
                            </div>
                        </Card.Body>
                        </Card>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

let welcome_randomized = "Hello there, welcome!"

function randomized_char () {
    let selectChars = [
        'A','B','C','D',
        'E','F','G','H',
        'I','J','K','L',
        'M','N','O','P',
        'Q','R','S','T',
        'U','V','W','X',
        'Y','Z', 
        '!','§','$','%',
        '&','/','(',')',
        '=','?','_','<',
        '>','^','°','*',
        '#','-',':',';','~',
        '1', '2', '3', '4',
        '5', '6', '7', '8',
        '9', '0',
    ];
    let randString = ""
    for (let i = 0; i < welcome_randomized.length; i++) {
        const randomElement = selectChars[Math.floor(Math.random() * selectChars.length)];
        randString += randomElement
    }; 
    return randString
}

function RenderWelcome () {
    const [newName, setnewName] = useState("");
    const [count, setCount] = React.useState(0);
    const shuffle = useCallback(() => {
        if (count % 4 === 0 && count !== 0) {
            setnewName(welcome_randomized);
        } else {
            setnewName(randomized_char());
        };
        setCount(count + 1);
    }, [count]);

    useEffect(() => {
        if (newName === welcome_randomized) {
            const intervalID = setInterval(shuffle, 7000);
            return () => clearInterval(intervalID);
        } else {
            const intervalID = setInterval(shuffle, 550);
            return () => clearInterval(intervalID);
        };

    });
    return newName;
}

export default Home;
