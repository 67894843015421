import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

  
function OpsMidstream () {
    return (
        <Container className="" fluid>
            <Row className="justify-content-md-left mt-5" >
			<h1 className="m-header">Operations Midstream</h1>
			<div className='m-para'>
				<p>
						<br/>
						Carrying on from the extraction of oil, transportation is required to bring it to the refineries. Most of the oil fields are centered in the Persian Gulf, West Africa, North Sea and the Med Sea. Refineries on the other hand are mainly found in places with little oil, for example, Asia. The extracted oil are transported via tankers, pipeline, railway and aircraft. Conversely, refined products will also need to be transported out from the refineries to other locations.
						<br/>  
						<br/>
						<font className='overview_container_para_header'><b>1. Tankers</b></font>
						<br/>  
						<br/>
						Tankers can either carry clean products and dirty products. Clean Tankers will always try to carry the same product in order to maintain the purity. It may switch to become a dirty tanker but it will take a few months to clean the tanks if she wants to carry clean products again. 
						<br/>  
						<br/>
						 <b>a. How many types of tanks are there?</b>
						<br/>  
						<br/>
						The answer is 3, cargo, ballast and slops.
						<br/>  
						<br/>						
						These tankers have several cargo tanks and may carry many types of products at a single point in time. In order to keep the oxygen level low in the tanks, inert gases are used to minimize the combustibility. The depth of the product in the tanker is called innage while the length of the air above it is called outage or ullage.
						<br/>
						<br/>
						Ballast tankers store seawater in order to maintain the ship's stability when it is empty. However, when the ship passes through a canal, she might need to discharge some water in order to have sufficient distance between the ship and the sea floor.
						<br/>
						<br/>
						Once the tanker has offloaded the cargo, high pressure jets are used to remove any residual oil hanging onto the tanks. Slop tanks are used to collect these unwanted mixture and are then dischaged periodically at ports with facilities to handle it.
						<br/>
						<br/>
						<font className='overview_container_para_header'><b>2. Pipeline</b></font>
						<br/>  
						<br/>
						The point where the pipelines meet are called hubs and they can be segregated into 2 categories, oil and clean product pipelines. Black oil pipeline transports oil to the refineries or storage sites while clean product pipelines carry products out from the refinery to distribution hubs. Going into detail the types of pipelines available for each cateogry, there are long haul and short haul lines.
						<br/>  
						<br/>
						Long haul lines have large diameters and have fewer delivery points. The quantity of oil is ensured but the number of moles might not remain the same. On the other hand, short haul lines have smaller diameters and the number of moles will be guaranteed. They also have more delivery lines.
						<br/>  
						<br/>
						Pumps are used to move the oil and chemicals are sometimes added to reduce drag. Once in a while, pigs are sent to clean the pipes as they can get clogged due to the type of products it carries.
						<br/>  
						<br/>
						<font className='overview_container_para_header'><b>3. Trucks, Railway and Aircrafts</b></font>
						<br/>  
						<br/>
						These modes of transport are not often used as they are pretty expensive and limited in terms of the volume they can carry. Trucks do provide greater flexibility and are popular with small independent refiners or for places that are hard to reach. Trains were mostly used in the old days although they can still be found in the US or Russia.
						<br/>  
						<br/>
				</p>
			</div>

            </Row>
        </Container>
)}

export default OpsMidstream;
