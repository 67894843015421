import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

  
function OpsDownstream () {
    return (
        <Container className="" fluid>
            <Row className="justify-content-md-left mt-5" >
			<h1 className="m-header">Operations Downstream</h1>
			<div className='m-para'>
			<p>
					<br/>
					The raw oil extracted from the oil field will be refined into different products. Depending on how the refinery was built, a mix of crude might be required as feedstock. This is also know as crude slate. Here are some aspects of the downstream operations.
					<br/>
					<br/>
					<font className='overview_container_para_header'><b>1. Refining</b></font>
					<br/>  
					<br/>
					For every barrel of oil used as feedstock, there could be refinery gains, meaning more than a barrel worth of products will be produced. This depends on how the refinery is configured i.e having more sophisticated cracking or distillation columns will help in this aspect. The primary process of refining is distillation. After which there are secondary processes to improve the quantity and quality of the products. 
					<br/>
					<br/>
					 <b>a. Separation</b>
					<br/>
					<br/>
					All impurities like salt have to be removed in order to prevent corrosion and increase the lifetime of all the parts. The purified stream then enters a distillation unit. Some types of distillation units include atmospheric distillation where the feed is distilled at atmospheric pressure. One benefit of doing so is that the distillation column would not need thick walls to withstand the pressure, hence saving cost. Another name for this is the crude distillation unit (CDU). The next kind of distillation column is vaccum distillation where the pressure is less than the atmospheric pressure. This allows products to vaporize more easily since the boiling point is reduced. Usually, vaccum distillation is used for the heavier end of the product chain such as vaccum gasoil.
					<br/>
					<br/>
					 <b>b. Conversion</b>
					<br/>
					<br/>
					The downside with just relying on distillation columns is that the yields of each finished product cannot be controlled. It depends solely on the crude slate. In order to further increase the yields of certain kinds of product like gasoline of naphtha, cracking is required which breaks down large hydrocarbon molecules into smaller ones. Some methods include coking, thermal cracking, catalytic cracking and viscracking. Depending on which configuaration you use, one would be able to use cheaper heavier oil and increase the yield of high value products. Besides breaking molecules, one can combine or alter the arrangement of molecules.
					<br/>
					<br/>
					 <b>c. Treatment and Blending</b>
					<br/>
					<br/>
					Once again to ensure the quality of the product, it undergoes treatment to remove any impurities. After which, refiners may choose to blend their product to further boost the value. For example, MBTE or some other chemical can be added to gasoline to boost the octane rating. 
					<br/>
					<br/>
					<font className='overview_container_para_header'><b>2. Distribution</b></font>
					<br/>  
					<br/>
					Similar to the midstream operations where oil is transported to the refinery, the finished products have to travel to hubs or places of interests like petrol kiosk. They could also be exported out to other countries that lack refining capacities.
					<br/>  
					<br/>
					<font className='overview_container_para_header'><b>3. Marketing</b></font>
					<br/>  
					<br/>
					Given that there are so many petroleum companies in the world all fighting to sell their product, each company must devote apart of their resources in advertising and marketing. For example, Esso created a slogan 'Put a tiger in your tank' to give the impression that their gasoline is more powerful than anyone else in the market. Loyalty discounts were given as well at kiosks to woo more customers.
					<br/>  
					<br/>
				</p>
			</div>

            </Row>
        </Container>
)}

export default OpsDownstream;
