import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
  
function AIS () {
    return (
        <Container className="" fluid>
            <Row className="justify-content-md-left mt-5" >
			<h1 className="m-header">What is AIS?</h1>
            <div className="m-para">
                <p className="m-para">
                    <b>Automatic Identification System (AIS)</b> is an automated tracking system to exchange information on navigation. It mainly consist of a transponder and several other equipment like a Global Positioning System (GPS), gyrocompass, Very High Frequency (VHF) transmitter. Vessels with a AIS transceiver can exchange static or dynamic information with AIS receiving stations (i.e other vessels, satellites or stations onshore). It is compulsory for all vessels over 300 GT and passenger vessels, according to the IMO SOLAS Agreement in 2002, to have a class A AIS transceiver to prevent collisions and to facilitate marine traffic.
                    <br/>
                    <br/>
                    Given that there are many vessels and thus transponders, Time Division Multiple Access (TDMA) is used to prevent devices from transmitting at the same time, thus resulting in data loss or interference. Each transponder will reserve and claim a 26.6 millisecond window to send out the signal on 2 channels 161.975 Mhz and 162.025 Mhz. Priority is given based on proximity as well as the type of the TDMA, SOTDMA (Self-Organized Time-Division Multiple Access) or CSTDMA (Carrier Sense Time-Division Multiple Access). There are of course other types of TDMA but these are 2 mainly used by vessels. More information can be found in the link below.
                    <br/>
                    <br/>
                    SOTDMA employs a algorithm to determine which slots are in use base on the timestamp sent in each data transmission before reserving timeslots. It is dynamic and self organizing. In places where there is high concentration of trnasponders, slots occupied by vesssels furthest away will be reused for its own transmission. CSTDMA on the other hand continuously monitor the background noise level of the ais channels. When a tranmission is required, the signal strength of a randomly picked slot is measured and compared to the backgound noise level. If it were similar, it was assumed to be unused and a transmission is made. Future slots cannot be reserved using this method and if no slot can be found, transmission will be delayed. SOTDMA transmissions also carry more weight than CSTDMA
                    <br/>
                    <br/>
                    This brings us to the next question of which TDMA does the vessel use? There are 2 main types of AIS transponders, Class A and B. Class A transponders are equipped with SOTDMA technology while Class B uses CSTDMA. Besides the TDMA difference, there are also several other distinctions such as the frequency at which the signal will be transmitted and the strength of the signal which will be listed below. In essence, a Class B transponder is cheaper and more suited for smaller or recereational vessels albeit with a lesser features. Recently, a new class, Class B+ has been introduced with SOTDMA but it has a lower priority than a Class A SOTDMA when reserving slots.
                    <br/>
                    <br/>
                    <b>Functionality Comparison</b>
                    <br/>
                    <br/>
                    A transmission of a higher power would equate to the ability to transmit a longer range
                </p>
				<table className="tg">
					<thead>
						<tr>
							<th className="tg-0pky">Function</th>
							<th className="tg-0pky">Class A</th>
							<th className="tg-0pky">Class B+</th>
							<th className="tg-0pky">Class B</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="tg-0pky">Transmission Power (W)</td>
							<td className="tg-0pky">12.5</td>
							<td className="tg-0pky">5</td>
							<td className="tg-0pky">2</td>
						</tr>
						<tr>
							<td className="tg-0pky">Transmission Rate (Seconds)</td>
							<td className="tg-0pky">~2-3</td>
							<td className="tg-0pky">~5</td>
							<td className="tg-0pky">30</td>
						</tr>
						<tr>
							<td className="tg-0pky">Minimum Keyboard + Display (MKD)</td>
							<td className="tg-0pky">Yes</td>
							<td className="tg-0pky">No</td>
							<td className="tg-0pky">No</td>
						</tr>
						<tr>
							<td className="tg-0pky">Technology</td>
							<td className="tg-0pky">SOTDMA</td>
							<td className="tg-0pky">SOTDMA</td>
							<td className="tg-0pky">CSTDMA</td>
						</tr>
						<tr>
							<td className="tg-0pky">Guaranteed Time Slot Allocation</td>
							<td className="tg-0pky">Yes</td>
							<td className="tg-0pky">Yes</td>
							<td className="tg-0pky">No</td>
						</tr>
						<tr>
							<td className="tg-0pky">Voyage Data</td>
							<td className="tg-0pky">Yes</td>
							<td className="tg-0pky">No</td>
							<td className="tg-0pky">No</td>
						</tr>
						<tr>
							<td className="tg-0lax">External GPS Connection</td>
							<td className="tg-0lax">Yes</td>
							<td className="tg-0lax">No</td>
							<td className="tg-0lax">No</td>
						</tr>
						<tr>
							<td className="tg-0lax">Price (£)</td>
							<td className="tg-0lax">2000</td>
							<td className="tg-0lax">650</td>
							<td className="tg-0lax">500</td>
						</tr>
					</tbody>
				</table>
				<br/>
				<p>
					<br/>
					<font className='overview_container_para_header'><b>Data Content</b></font>
					<br/>
					<br/>
					A class A transponder is able to send out more details for each transmission, including voyage information liek destination, eta, draught. Voyage and static information are manually keyed in the by the officer on board and are thus prone to human error.
				</p>
				<table className="tg">
					<thead>
						<tr>
							<th className="tg-0pky">Details</th>
							<th className="tg-0pky">Class A</th>
							<th className="tg-0pky">Class B and B+</th>
							<th className="tg-0pky">Data Type</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="tg-0pky">Vessel Name (up to 20 char)</td>
							<td className="tg-0pky">Yes</td>
							<td className="tg-0pky">Yes</td>
							<td className="tg-0pky" rowSpan="7">Static</td>
						</tr>
						<tr>
							<td className="tg-0pky">Call Sign</td>
							<td className="tg-0pky">Yes</td>
							<td className="tg-0pky">Yes</td>
						</tr>
						<tr>
							<td className="tg-0pky">IMO</td>
							<td className="tg-0pky">Yes</td>
							<td className="tg-0pky">No</td>
						</tr>
						<tr>
							<td className="tg-0pky">Vessel Type</td>
							<td className="tg-0pky">Yes</td>
							<td className="tg-0pky">Yes</td>
						</tr>
						<tr>
							<td className="tg-0pky">Dimensions</td>
							<td className="tg-0pky">Yes</td>
							<td className="tg-0pky">Yes</td>
						</tr>
						<tr>
							<td className="tg-0lax">Location of the positioning system's antenna on board the vessel</td>
							<td className="tg-0lax">Yes</td>
							<td className="tg-0lax">No</td>
						</tr>
						<tr>
							<td className="tg-0lax">Type of positioning system</td>
							<td className="tg-0lax">Yes</td>
							<td className="tg-0lax">Yes</td>
						</tr>
						<tr>
							<td className="tg-0lax">Draught, 0.1 to 25.5m (m)</td>
							<td className="tg-0lax">Yes</td>
							<td className="tg-0lax">No</td>
							<td className="tg-0pky" rowSpan="3">Voyage/Dynamic</td>
						</tr>
						<tr>
							<td className="tg-0lax">Destination (up to 20 char)</td>
							<td className="tg-0lax">Yes</td>
							<td className="tg-0lax">No</td>
						</tr>
						<tr>
							<td className="tg-0lax">ETA (UTC month/date hours:minutes)</td>
							<td className="tg-0lax">Yes</td>
							<td className="tg-0lax">No</td>
						</tr>
						<tr>
							<td className="tg-0lax">MMSI</td>
							<td className="tg-0lax">Yes</td>
							<td className="tg-0lax">Yes</td>
						<td className="tg-0pky" rowSpan="9">Dynamic</td>
						</tr>
						<tr>
							<td className="tg-0lax">AIS Navigational Status</td>
							<td className="tg-0lax">Yes</td>
						<td className="tg-0lax">No</td>
						</tr>
						<tr>
							<td className="tg-0lax">Rate of Turn (degrees per minute)</td>
							<td className="tg-0lax">Yes</td>
						<td className="tg-0lax">No</td>
						</tr>
						<tr>
							<td className="tg-0lax">Speed (knots)</td>
							<td className="tg-0lax">Yes</td>
						<td className="tg-0lax">Yes</td>
						</tr>
						<tr>
							<td className="tg-0lax">Coordinates</td>
							<td className="tg-0lax">Yes</td>
						<td className="tg-0lax">Yes</td>
						</tr>
						<tr>
							<td className="tg-0lax">Course</td>
							<td className="tg-0lax">Yes</td>
							<td className="tg-0lax">Yes</td>
						</tr>
						<tr>
							<td className="tg-0lax">Heading</td>
							<td className="tg-0lax">Yes</td>
							<td className="tg-0lax">Yes</td>
						</tr>
						<tr>
							<td className="tg-0lax">Bearing</td>
							<td className="tg-0lax">Yes</td>
							<td className="tg-0lax">Yes</td>
						</tr>
						<tr>
							<td className="tg-0lax">Received Time UTC</td>
							<td className="tg-0lax">Yes</td>
							<td className="tg-0lax">Yes</td>
						</tr>
					</tbody>
				</table>
				<p>
					<br/>
					<font className='overview_container_para_header'><b>Tranmission Frequency</b></font>
					<br/>
					<br/>
					Depending on the type of data (static or dynamic), the frequency at which the signal is suppose to be sent out differs too. Another factor would be the ship's dynamic properties such as speed as outlined in the table below
				</p>
				<table className="tg">
					<thead>
						<tr>
							<th className="tg-0pky">Ship Details</th>
							<th className="tg-0pky">Class A</th>
							<th className="tg-0pky">Class B+</th>
							<th className="tg-0pky">Class B</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="tg-0pky">Ship at Anchor or Moored</td>
							<td className="tg-0pky">3 mins</td>
							<td className="tg-0pky">3 mins</td>
							<td className="tg-0pky">3 mins</td>
						</tr>
						<tr>
							<td className="tg-0pky">0-2 knots</td>
							<td className="tg-0pky">10 secs</td>
							<td className="tg-0pky">3 mins</td>
							<td className="tg-0pky">3 mins</td>
						</tr>
						<tr>
							<td className="tg-0pky">2-14 knots</td>
							<td className="tg-0pky">10 secs</td>
							<td className="tg-0pky">30 secs</td>
							<td className="tg-0pky">30 secs</td>
						</tr>
						<tr>
							<td className="tg-0pky">2-14 knots and changing course</td>
							<td className="tg-0pky">3.3 secs</td>
							<td className="tg-0pky">30 secs</td>
							<td className="tg-0pky">30 secs</td>
						</tr>
						<tr>
							<td className="tg-0pky">14-23 knots</td>
							<td className="tg-0pky">6 secs</td>
							<td className="tg-0pky">15 secs</td>
							<td className="tg-0pky">30 secs</td>
						</tr>
						<tr>
							<td className="tg-0pky">14-23 knots and changing course</td>
							<td className="tg-0pky">2 secs</td>
							<td className="tg-0pky">15 secs</td>
							<td className="tg-0pky">30 secs</td>
						</tr>
						<tr>
							<td className="tg-0pky">&gt; 23 knots</td>
							<td className="tg-0pky">2 secs</td>
							<td className="tg-0pky">5 secs</td>
							<td className="tg-0pky">30 secs</td>
						</tr>
						<tr>
							<td className="tg-0lax">Static Information</td>
							<td className="tg-0lax">6 mins</td>
							<td className="tg-0lax">6 mins</td>
							<td className="tg-0lax">6 mins</td>
						</tr>
					</tbody>
				</table>
				<p>
					<br/>
					You may find more explanation on the data below
				</p>
				<ul className='overview_container_para'>
					<li>Heading</li>
						<ul>
							<li>The direction at which the nose of the vessel is pointing at that point in time</li>
						</ul>
					<li>Course</li>
						<ul>
							<li>The direction at which the vessel would like to travel to after taking into account winds, tidal movements etc</li>
						</ul>
					<li>Draught</li>
						<ul>
							<li>Vertical distance between the bottom of the ship's hull and the waterline</li>
						</ul>
					<li>Coordinates</li>
						<ul>
							<li>Longitude and latitude of the vessel</li>
						</ul>
					<li>IMO number</li>
						<ul>
							<li>A unique identification number provided by the IMO organization. This number does not change even when the vessel owner, country of registry changes</li>
						</ul>
					<li>Call sign</li>
						<ul>
							<li>A unique identification tag for the vessel to transmit signals, consisting of the vessel's registered flag code in front. Therefore, one would be able to decipher which country the vessel was registered in with the call sign</li>
						</ul>
					<li>Maritime Mobile Service Identity (MMSI)</li>
						<ul>
							<li>A 9 digit long number sent digitally over a radio frequency to uniquely identify vessels as well. Part of the MMSI contain the vessel's registered flag. Hence, like the callsign, can be used to decipher the flag state</li>
						</ul>
					<li>AIS Navigational Status</li>
						<ul>
							<li>Shows whether the vessel is anchored or underway etc.</li>
						</ul>
					<li>Type of positioning system</li>
						<ul>
							<li>GPS, DGPS, Loran-C</li>
						</ul>
					<li>Vessel type</li>
						<ul>
							<li>Tanker or fishboat etc.</li>
						</ul>
				</ul>
				<p>
					<br/>
					<font className='overview_container_para_header'><b>Satellite vs Terrestrial</b></font>
					<br/>
					<br/>					
					There are 2 types of AIS signals, Terrestrial AIS (T-AIS) and Satellite AIS (S-AIS). Signals received via base stations are called T-AIS and typically can receive signals within a range of 15-20 nautical miles. If the station were at a higher altitude, it would be able to reive signals from a wider range. Other factors that will affect the range include weather, antenna type and obstacles.
					<br/>
					<br/>
					Satellites or S-AIS are able to pick up signals from a wider range than T-AIS and at remote areas without a terrestrial station, however, transmissions may get scrambled or lost because satellites might pick up data using the same slot from different areas. This is otherwise known as packet collision.
					<br/>
					<br/>
					On top of the method at which transmissions are received, there are at least 27 types of messages that can be sent from the transponder. Each message type has different priorities, access schemes to transmit data and data content (that includes the quality od the data). For example, message 27 is able to travel a longer distance as it is compressed and coordinates might have lesser significant figures.
				</p>
				<p>
					<font className='overview_container_para_header'>References</font>
				</p>
				<font className='overview_container_para'>
					<ul>
						<li><a className="cool-link" href="http://www.allaboutais.com/index.php/en/technical-info/transmission-types/105-ais-technicals/technical-lfundamentals">All about AIS</a></li>
						<li><a className="cool-link" href="https://digitalyacht.net/2018/11/26/class-b-sotdma/">Digital Yacht</a></li>
						<li><a className="cool-link" href="https://help.marinetraffic.com">MarineTraffic</a></li>
						<li><a className="cool-link" href="https://www.navcen.uscg.gov/">USCG, message types</a></li>
					</ul>
				</font>
				<br/>  
				<br/>
            </div>
            </Row>
        </Container>
)}
// ReactDOM.render(<MyComponent />, document.body);
export default AIS;
