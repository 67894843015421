import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

  
function PythonDjango () {
    return (
        <Container className="" fluid>
            <Row className="justify-content-md-left mt-5" >
			<h1 className="m-header">Python Django Tutorial</h1>
			<div className='m-para'>
				<p>
				* my first website v1 was based on this
				</p>
				<p>
				Combed the internet to find out how to use Django to build a site and this is the result of several webpages. Initally thought of hosting it on a linode server but deployment and configuration can be tedious. Heroku makes this part a lot easier. Original links can be found at the bottom of this page. It was a fun introduction to website building in general, perhaps Node JS or Ruby on Rails next? Working on providing sample files.
				</p>
				<b>a. Installing Django and virtual environment</b>
				<br/>

				Install virtualenv on bash. Django requires you to install a virtual environment in order to run. A virtual environment allows you to operate without having to worry about your other project's dependencies should you have to upgrade or change a setup. Create a folder (assuming you would like it in your desktop folder), and enter it
				<blockquote><pre><code>
				pip install virtualenv<br/>
				mkdir myproject<br/>
				cd ~/Desktop/myproject
				</code></pre></blockquote>

				Start the virtual environment, let's call it myproject_venv and activate it
				<blockquote><pre><code>
				virtualenv myproject_venv<br/>
				source myproject_venv/bin/activate
				</code></pre></blockquote>

				Next, install Django and Gunicorn whilst in the virtual environment
				<blockquote><pre><code>
				pip install Django Gunicorn
				</code></pre></blockquote>

				Now we are ready to start our first Django project and application. Let's create the django project with respect to your current folder 'my_project'. This prevents your folders from getting too nested and keeps it tidy. After which, we can test if the website is good on 'http://localhost:8000/' by running the thrid command in the block below. Control C to kill the command after that
				<blockquote><pre><code>
				django-admin.py startproject my_project ~/my_project<br/>
				python manage.py startapp mysite<br/>
				python manage.py migrate<br/>
				python manage.py runserver 0.0.0.0:8000<br/>
				</code></pre></blockquote>
				That's not quite very interesting to look at, hence, if you have any static files, put them in the static folder under my_project. After which, your file structure should look like this

				<blockquote><pre><code>
				my_project<br/>
				|-manage.py<br/>
				|-my_project<br/>
				|---|-settings.py<br/>
				|---|-urls.py<br/>
				|---|-wsgi.py<br/>
				|---|-static<br/>
				|-------|-css<br/>
				|-------|-js<br/>
				|-mysite<br/>
				|---|-admin.py<br/>
				|---|-apps.py<br/>
				|---|-models.py<br/>
				|---|-tests.py<br/>
				|---|-views.py<br/>
				|---|-templates<br/>
				|-------|-test.html<br/>
				</code></pre></blockquote>

				Add the following 2 lines into your settings.py file.

				<blockquote><pre><code>
				STATIC_ROOT = os.path.join(PROJECT_ROOT, 'staticfiles')<br/>
				STATICFILES_DIRS = (<br/>
					os.path.join(PROJECT_ROOT, 'static'),<br/>
				)<br/>
				</code></pre></blockquote>
				Now we need to create the function to call the html template. In your view.py, add

				<blockquote><pre><code>
				from django.shortcuts import render, render_to_response<br/>

				# Create your views here.<br/>
				def index(request):<br/>
					return render_to_response('index.html')<br/>
				</code></pre></blockquote>
				Add the following to urls.py

				<blockquote><pre><code>
				from django.conf.urls import url<br/>
				from django.contrib import admin<br/>
				from mysite import views<br/>
				from django.contrib.staticfiles.urls import staticfiles_urlpatterns<br/>

				urlpatterns = [<br/>
					url(r'^admin/', admin.site.urls),<br/>
					url(r'^$', views.index, name='index'),<br/>
				]<br/>
				urlpatterns += staticfiles_urlpatterns()
				</code></pre></blockquote>
				Now all we need to do is to add the first line below on the very top of every html template file. If you need to reference any static file in the html code after that, ensure the 'href portion' is changed like so. Run the manage.py command again to check your changes.
				<br/>  
				<samp><code><pre>
				&#123;%load staticfiles%&#125;<br/>
				href="&#123;%static 'css/index.css'%&#125;
				</pre></code></samp>
				<br/>

				<b>b. Deploying to Heroku, see the link below for the guide! &#91;Deprecated&#93;</b>
				<br/>
				<br/>

				<b>References</b>
					<br/>
						<ul>
							<li><a className="cool-link" href="https://www.youtube.com/watch?v=PqeAvFf_HDI&t=">Youtube Django Tutorial</a></li>
							<li><a className="cool-link" href="https://www.digitalocean.com/community/tutorials/how-to-set-up-django-with-postgres-nginx-and-gunicorn-on-debian-9">Digital Ocean Django</a></li>
							<li><a className="cool-link" href="https://simpleisbetterthancomplex.com/tutorial/2016/08/09/how-to-deploy-django-applications-on-heroku.html">Deploying to Heroku! &#91;Deprecated&#93;</a></li>
						</ul>

			</div>


            </Row>
        </Container>
)}

export default PythonDjango;
