import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import refEconomics from './img/ref_economics.svg';

  
function RefineryEconomics () {
    return (
        <Container className="" fluid>
            <Row className="justify-content-md-left mt-5" >
			<h1 className="m-header">Refinery Economics</h1>
			<div className='m-para'>
			<p>
					<br/>
					<br/>
					There are several factors from the upstream processes to downstream that would influence how profitable a refinery will be. Below is a image seperating the factors into 2 categories, asset and operational. Potential indicators to measure each factor can be found in the diagram as well.
					<br/>
					<br/> 
					<img src={refEconomics} className="display-flex m-5" alt="refEconomics" width="100%" height="100%" />
					<br/>
					<br/>
					<font><b>Lets take a look at the assets first</b></font>
					<br/>
					<br/>
					 <b>a. Refinery Complexity</b>
					<br/>
					<br/>
					Using the Nelson Complexity Index (NCI), a score can be assigned to each piece of equipment based on its complexity and the summation of the scores will give the NCI of a refinery. The more units a refinery has, the higher the score will be, indicating how equipped it is to handle more variations.
					<br/>
					<br/> 
					Why is this important?
					<br/>
					<br/> 
						The crude slate that a refinery can take can change due to several external factors. For example, due to political turmoil, oil from a certain country can no longer be exported. It will then have to be sourced from another location which can have different qualities. Crude can be more sour (higher sulphur content) or heavier (containing a higher proportion of longer hydrocarbons) hence affecting the product output. If the refinery has more complex units like a FCC in addition to the CDU, lighter and more valuable products can still be obtained from sour and heavy crude.
					<br/>  
					<br/>
					In addition to being able to handle different kinds of crude slates, the refinery can adjust the product output to suit the weather. For example, in winter, refineries would want to increase the output of heating oil.
					<br/>
					<br/>
					 <b>b. Location of refinery and transportation</b>
					<br/>
					<br/>
					The raw input of crude oil and various other feedstock, as well as the products, have to be transported to the refinenry or customers respectively. Pipelines and marine tankers remain the most popular way of transportation as they are cheaper than trains or trucks. This ties in very closely to the location of the refinery. If the refinery is close to the oil field or port, the cost of transportation will decrease naturally.
					<br/>
					<br/>
					<font><b>Operational</b></font>
					<br/>  
					<br/>
					Refineries operate 24/7 and aim to do so with the greatest efficiency. While taking into account the crude slate, inventory level, market conditions etc., operating conditions of the units must be adjusted acccordingly. Regular checks are scheduled to ensure all sections are working well to prevent unnecessary downtime. Typically, refineries shut down during the summer for planned maintenance.
					<br/>  
					<br/>
					<font><b>What are some of the indicators for refinery economics?</b></font>
					<br/>  
					<br/>
					 <b>a. Crack Spread</b>
					<br/>
					<br/> 
					Gross Refinery Margin(GRM) is the difference in value between the products and the feedstock. It is used to gauge the effects of evolving market conditions. Another usage of this indicator is to measure the differences in yield across the refineries. Having a higher GRM would indicate a greater 'crack' spread
					<br/>
					<br/>
				</p>
			</div>

            </Row>
        </Container>
)}
// ReactDOM.render(<MyComponent />, document.body);
export default RefineryEconomics;
