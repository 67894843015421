import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

  
function TankerMarketParties () {
    return (
        <Container>
            <Row className="justify-content-md-left mt-5" >
				<h1 className="m-header">Market and Parties</h1>
				<div className='m-para'>
				<p>
					<br/>
					In this section, we explore the parties involved in managing and operating the vessels. Certain aspects of the freight market and the economy can be found here as well.
					<br/>
					<br/>
					<font><b>1. Chain of Parties</b></font>
					<br/>
					<br/>
					 <b>a. Owners</b>
					<br/>
					<br/>
					The vessel owner can refer to several parties in a chain. For example, the main or head owner can be a person of an investment company and they will lease it out to other parties. If the vessel is owned by a ship company, they are usually the ones doing the technical management although they can be passed unto a shipper via bareboat charter. From a commerical point of view, the vessel controller or owner at present can be the head owner, desponent owner/operator or the commercial manager depending on the contracts and if they exploit the ship for cargo delivery . Usually shipowners hire technical and commerical managers to the ship.
					<br/>
					<br/>
					 <b>b. Agents</b>
					<br/>
					<br/>
					The agent represents the vessel's owner and act in their best interests. Port agents are responsible for organizing and coordinating the port call. Ensuring everything goes smoothly and the turnaround is fast. They also provide supplies to the ships Another type of agent called Cargo Agents can sometimes be appointed by the owner to look for goods to be transported by the vessel
					<br/>
					<br/>	
					 <b>c. Managers</b>
					<br/>
					<br/>
					 <b>-. Commercial Managers</b>
					<br/>
					<br/>
					Commercial Managers are in charge of seeking a charter for the vessel. They negotiate the emplopyment of the vessel and conclude the chareter parties or contracts related to it.
					Charters can be on a voyage basis or time charter depending the owners request. The operational aspect of it involves liasing with port agents, brokers to ensure all issues are resolved promptly and voyages are executed smoothly
					<br/>
					<br/>				
					 <b>-. Technical Managers</b>
					<br/>
					<br/>
					Technical Managers ensure that the physical condition of the vessel is well maintained carefully and function at its highest efficiency. Vessels have to follow regulations set out by the government and IMO. They are responsible fot supervising maintenance works, scheduling repairs etc.
					<br/>
					<br/>				
					 <b>-. International Safety Managers</b>
					<br/>
					<br/>
					ISM are primarily in charge of ensuring safety onboard a vessel. For example, preventing human injury or loss of life or avoid environmental damage. Sometimes when travelling through areas popular with pirates, armed security teams might be employed
					<br/>
					<br/>
					 <b>d. Operators</b>
					<br/>
					<br/>
					Operators are in charge of running the daily operations onboard a vessel. This includes managing the crew and delegating tasks.
					<br/>
					<br/>
					 <b>e. Brokers</b>
					<br/>
					<br/>
					Shipbrokers act as a middleman between the parties involved, ensuring a fair price can be negotiated and agreed upon. They may also helpa party to find what cargoes are available and market state news. 
					<br/>
					<br/>
					<font><b>2. Freight Market</b></font>
					<br/>
					<br/>
					 <b>a. Economic Indicators</b>
					<br/>
					<br/>
					 <b>-. Demand</b>
					<br/>
					<br/>
					Demand for freight depends on several indicators but the most important important factor is the export of raw materials and semi finished products to importing countries, as well as the movement of manufactured products. In other words, the world economy, which is dependent on the business cycle and trade elasticity. The business cycle refers to economic growth and how it fluctuates thus creating a cyclical demand for sea transport. It influences the short term fluctuations and predictions are diffcult due to the need to do deep research in many areas. For example, the intneraction between consumption and investment, time lags, stockbuilding and mass psychology. Long term fluctuations invole taking a look at the trade elasticity rwhich is the percentage of sea trade growth divided by the percentage of growth in industrial production. For example, changes in the economic structures of countries.
					<br/>
					<br/>
					The next factor to consider is the seaborne commodity trades which is dependent on the market for the commodity prices as well as countries changing their supply sources. One has to look at the seasonality like the weather patterns where heating oil will be in demand during winter. Factories may also reloacate to areas with lower operating cost which could affect transport prices. Some indicators that maybe looked at could be oil refinery utilization rates, location imbalances stock, spot and forward prices.
					<br/>
					<br/>
					Random shocks have to be taken into account as well such as political turmoil which could cut off the supply of certain goods and even natural disasters. Oil producing country at war will drive the demand up for freight in other countries where comapnies can move cargo more safely.
					<br/>
					<br/>
					 <b>-. Supply</b>
					<br/>
					<br/>
					In the long run, fleet growth is determined by the merchant fleet, meaning the breakdown of the number of vessels in each category like container or tankers. A small portion of the fleet is sent for demolition each year, hence the pace of adjustment is measured on an annual basis. One could also look at ffleet productivity measured in ton miles per DWT which is dependent on the speed of the vessel (a higher speed would equate a voyage taking less time to complete), port time, and deadweight utilisation. 
					<br/>
					<br/>
					Another supply factor could be the ability for shipyards to build new vessels (orderbookd) as well as those being sent for demolition. Environmental policies across countries play a part as well. However, perhaps the most important regulator would be prevailing freight rates. This serves to motivate the market to adjust the capacity in the short term and reduce long term costs				
					<br/>
					<br/>
					 <b>b. What are the markets in shipping?</b>
					<br/>
					<br/>
					 <b>1. New Builds</b>
					<br/>
					<br/>
					In the new builds markets, trades are done for vessels that do not exist yet by signing a letter of intent. Contracts are built on a pre set of specifications and design. These contracts are complex and the ship will not available for 2-3 years from signed contract. Having such contracts where the specifications are locked in can have several disadvantages such as not aligning with changing regulations from the IMO. Market conditions fluctuate as well, making new builds less lucrative.
					<br/>
					<br/>
					People who trade in this market can have various reasons like new builds being cheaper than second hand ships sold in Sales and Purchase or requiring the vessel to be built in a certain way. Another reason could the shipping company's policy of replacing the old fleet.
					<br/>
					<br/>
					 <b>2. Freight</b>
					<br/>
					<br/>
					This market involes the selling and buying of transport in the Baltic Shipping Exchange. International Commercial Terms (Incoterms) are used to standardize and communicate the risks and responisibilities associated to the transportation of goods for each party. Once the deal has been agreed of fixed, the terms are set out by the charter party in such a way that it protects the position of the parties involved. This market can be segregated into 2, the first where the actual transport is purchased. In this area, there are 4 kinds of contracts.
					<br/>
					<br/>
					-. Voyage Charter
					<br/>
					<br/>
					The ship owner sells transport at a fixed price per ton of cargo from the port of loading to the port of discharge. It can be a single or multi voyage. The owner pays the port costs (excluding stevedoring), fuel costs and crew costs. Charterer loads cargo and pay owners freight (cargo per unit loaded). Most use the Worldscale Index to negotiate the price per barrel of oil more conveniently depending on the routes involved. For the dry bulk market, the Baltic Dry Index (BDI) is used instead which is the avergae of the Baltic Handymax Index (BHMI), the Baltic Panamax Index (BPI) and the Baltic Capesize Index (BCI). 
					<br/>
					<br/>
					-. Contract of Affreightment (COA)
					<br/>
					<br/>
					The shipowner transports regular quantities of a specific cargo, with ships and routes of his choice within the agreed shipment period. The cargo is then made available by the charterer for shipment by that vessel in that shipment period. The benefit of a COA to sellers or buyers of goods is that it gives them control over a vessel or vessels during periods when they have need of a vessel without the necessity of paying hire during periods when they have no need for the vessel.
					<br/>
					<br/>
					-. Time Charter
					<br/>
					<br/>
					Time Charters are agreements between the shipowner and charterer to hire the ship and crew but instructions are given by the shipowner. These charters are often charged per day. The owner is responsible for the technical aspect like providing a crew, maintenance and insurance while the time charterer will take commercial management and operation. This means that the charterer will have to pay port, agency, bunker and the costs of dealing with the cargo. Charterers are indemified against vessel accidents caused by the crew and have to be compensated due to time lost. They are however in charge of ensuring the cacrgo is handled carefully. 
					<br/>
					<br/>
					-. Bare boat charter
					<br/>
					<br/>
					Owner transfers the possession of the vessel to the charterer for a fee per day, over a period of time. It comes without a crew and the owner has no operational responsibilities. Bunker, port, operating, insurance and crew costs are bourne on the charterer. The owner is also indemnified against all liabilities incurred by the ship. This would be less complicated then being involved in the purchase of a second hand vessel which involes complicated finance and security arrangements.
					<br/>
					<br/>
					The other side of the freight market are the derivatives like the Forward Freight Agreement (FFA). These contracts are settled against an agreed future value of a freight market index. It is similar to placing a bet or people swapping freight costs, on whether the price will go up or down. Hence, depending on the outcome, one party will have to compensate the other. They provide a means of hedging exposure to risk by trading on specific time or voyage charter rates for forward position. Hedging uses a derivative contract to secure a physical position whereas speculation does not have such contracts involved.
					<br/>
					<br/>
					 <b>3. Sales and Purchase</b>
					<br/>
					<br/>
					Sscond hand ships are bought or sold between owners after negotiation. The new owner can take over the ship immediately depending when th ehandover date is specified and is sometimes able to benefit from an ongoing charter. Owners may sell their vessels to alter the balance sheet of the comapny or individual. Here is a brief outline of the steps when one wants to purchase a second hand vessel.
					<br/>
					1. Advertise the ship on the market
					<br/>
					2. Negotiation
					<br/>
					3. Memorandum of agreement
					<br/>
					4. inspectsio and closing
					<br/>
					<br/>
					 <b>4. Demolition</b>
					<br/>
					<br/>
					Demolition involves the demolition of vessels and the price fluctuates depending on the demand for scrap metal. The scrapyard must have the necessary space and labor to carry out the demolition.
					<br/>
					<br/>
				</p>
				</div>
            </Row>
        </Container>
)}
// ReactDOM.render(<MyComponent />, document.body);
export default TankerMarketParties;
